import { render, staticRenderFns } from "./ModalLampImportNew.vue?vue&type=template&id=34f2cbe0&scoped=true"
import script from "./ModalLampImportNew.vue?vue&type=script&lang=js"
export * from "./ModalLampImportNew.vue?vue&type=script&lang=js"
import style0 from "./ModalLampImportNew.vue?vue&type=style&index=0&id=34f2cbe0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f2cbe0",
  null
  
)

export default component.exports